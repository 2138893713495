import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';

import { FiPlusCircle } from 'react-icons/fi'


const imageStyle = {
  position: "relative",
  width: "100%",
  height:"100%",
  zIndex: 1,

}

const hoverStyle = {
  transition: "opacity 350ms ease",
  display: "flex",
  justifyContent: "center"
}

const displayOverStyle = {
  height: "100%",
  left: "0",
  position: "absolute",
  top: "0",
  width: "100%",
  zIndex: 2,
  transition: "background-color 350ms ease",
  backgroundColor: "transparent",
  padding: "20px 20px 0 20px",
  boxSizing: "border-box",
}

const descripcion = {
  position: "absolute",
  top: "40%"
}

const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

const imagePrefix = "data:image/jpeg;base64,";


const Overlay = (props) => {

  // const [hovered, updateHovered] = React.useState(false);
  const [hoveredOpacity, updateHoveredOpacity] = React.useState(0);
  const [overlayBackgroundColor, updateOverlayBackgroundColor] = React.useState("transparent");

  const handleMouseEnter = () => { //TODO cambiar a ES6
      updateHoveredOpacity(1);
      updateOverlayBackgroundColor("rgba(255,255,255,0.5)");
    };

  const handleMouseLeave = () => {
    //  updateHovered(false);
      updateHoveredOpacity(0);
      updateOverlayBackgroundColor("transparent");
    };

    return (
      <div
        style={imageStyle}
        onMouseEnter={(e) => handleMouseEnter()}
        onMouseLeave={(e) => handleMouseLeave()}
      >
          <Image style={{ width:"100%" }} responsive src={props.image} alt="Cargando imagen..."></Image>
            <div style={{...displayOverStyle, backgroundColor: overlayBackgroundColor}}>
            <div style={{...hoverStyle, opacity: hoveredOpacity}}>
              <FiPlusCircle style = {descripcion}/>
            </div>
          </div>
      </div>
    );

}

export default Overlay;

{/*https://codedaily.io/tutorials/62/Create-a-Hoverable-Display-Card-with-React-and-Emotion
  https://stackoverflow.com/questions/25037808/reactjs-component-fadein-fadeout-on-mouse-enter-mouse-leave
  https://www.w3schools.com/howto/howto_css_image_overlay_title.asp
  */}
