import React, { useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Product from './Product';
import ProductsSidebar from './ProductsSidebar';
import ProductModal from './ProductModal';
import { ProductsContext }  from '../productsContext';
import db from '../functions_and_vars';
import axios from 'axios';


const useProductsContext = () => {
  const { products, updateProducts } = useContext(ProductsContext);
  return { products, updateProducts };
};

const ProductosDesktop = (props) => {
  const { products, updateProducts } = useProductsContext();
  const [ modalShow, setModalShow ] = React.useState(false);
  const [ productDetail, setProductDetail ] = React.useState({nombre: [""]});

  // const breakpoint = 620;

  const getProductDetail = (clasificacion, id) => {
    setModalShow(true);

    axios.get(db + "/products/" + id, {
      params: {
        id: id,
        clasificacion: clasificacion
      }
    }).then((res) => {
      setProductDetail(res.data); // for modal TODO
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div style={{ height:"auto",
                  minHeight: "100vh",
                  minWidth: "100%",
                  width:"100%",
                  display: "grid",
                  gridTemplateColumns: "min-content 1fr",
                  gridAutoFlow: "column" }}>
      <div style={{ float: "left",
                    display: "flex",
                    background: "#222",
                    height: "100%",
                    width: "300px"}}>

      <ProductsSidebar/>

      </div>

      <div style={{display: "grid",
                   marginleft: "10px",
                   marginTop: "50px",
                   minHeight: "100%",
                   width: "100%",
                   alignItems: "center",
                   justifyContent: "center",
                   alignContent: "space-around",
                   gridTemplateRows: "0.1fr 5fr 0.4fr"}}>

        <div>
          <h1> PRODUCTOS </h1>
        </div>
        <div style={{position: "relative", display: "flex", width: "100%", justifyContent: "center", flexWrap: "wrap" }}>

          {
            products.data.map((item, i) => {
            return (
            <div style={{position: "relative",
                          width: "250px",
                          minWidth: "250px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          margin: 10}}
                          onClick={(e) => {
                            getProductDetail(item.clasificacion, item.id);
                            }
                          }
                          >
              <Product image={item.images[0]} titulo={item.nombre} />
            </div>)})
          }

          <ProductModal show={modalShow} onHide={() => setModalShow(false)} product={productDetail} />

        </div>
      </div>
    </div>
  );
}

export default ProductosDesktop;
