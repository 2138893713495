import React from 'react';
import ValoresDesktop from './ValoresDesktop';
import ValoresMobile from './ValoresMobile';

import ViewportProvider, { useViewportDimensions } from '../viewPortContext';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};


const Valores = () => {
  const { width, height } = useViewport();
  const breakpoint = 620;

  return (
    width < breakpoint ? <ValoresMobile/> : <ValoresDesktop/>
  );
}

export default Valores;
