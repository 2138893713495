import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}


// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

const ValoresMobile = () => {

  return (
    <Container className="container-section" fluid="md" style={{backgroundColor: "#f4f6f7", minWidth: "100%", textAlign: "center"}}>
      <Col style={{...paddingStyle, alignItems: "center"}}>
        <Row>
          <Image style={{ height:"100%", width:"100%"}} responsive src={require("../Images/embroidery-polo-shirts-with-logo.png")}></Image>
        </Row>
        <Row className="column-pair-second" style={{...paddingStyle, justifyContent: "space-around"}}>
              <h1 style={{fontSize: "xx-large"}}>Nuestros Valores</h1>
              <h4 style={{fontSize: "small"}}>
              C y G Diseños tiene una reconocida trayectoria y ha tenido un sostenido crecimiento gracias a su seriedad,
               innovación y compromiso irrestricto con sus clientes. Nos comprometemos a entregar el mejor
               servicio y atención a todos quienes confìan en nosotros.
              </h4>
              <p style={{fontSize: "x-small"}}>
              Nuestros productos se diferencian por su calidad y buen gusto, los cuales se confeccionan de forma personalizada para asegurar la mejor experiencia de compra.
              </p>
        </Row>
      </Col>
    </Container>
  );
}

export default ValoresMobile;
