import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';



class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      email: "",
      telefono: "",
      consulta: "",
      error: false
    }
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeConsulta = this.onChangeConsulta.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value
    });
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }
  onChangeTelefono(e) {
    this.setState({
      telefono: e.target.value
    });
  }
  onChangeConsulta(e) {
    this.setState({
      consulta: e.target.value
    });
  }

  onSubmit(e) {
      e.preventDefault();

      const newConsulta = {
        nombre: this.state.nombre,
        email: this.state.email,
        telefono: this.state.telefono,
        consulta: this.state.consulta
      };

      axios.post(this.props.post, newConsulta)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
          this.setState({
            error: true
          });
        });

      this.setState({
          nombre: '',
          email: '',
          telefono: '',
          consulta: '',
          error: false
      })
    }

  render () {
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Nombre</Form.Label>
            <Form.Control onChange={this.onChangeNombre}
                          type="text"
                          placeholder="Nombre Completo"
                          value={this.state.nombre} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control onChange={this.onChangeEmail}
                          type="email"
                          placeholder="nombre@ejemplo.com"
                          value={this.state.email} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control onChange={this.onChangeTelefono}
                          type="text"
                          placeholder="+56912345678"
                          value={this.state.telefono}/>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Consulta</Form.Label>
            <Form.Control onChange={this.onChangeConsulta}
                          as="textarea"
                          rows="5"
                          placeholder="Ingrese su consulta aqui"
                          value={this.state.consulta}/>
          </Form.Group>
          <Button variant="primary" type="submit"  >
            Enviar
          </Button>
          <p style={{color: "red"}}>{this.state.error ? "No se pudo enviar consulta. Por favor intentar nuevamente":""}</p>
        </Form>
      </div>
    );
  }
}

export default ContactForm;
