import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

//
// const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}
//
//
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };




const SomosMobile = () => {

  return (
    <Container fluid="md" style={{ maxWidth: "100%", marginTop:"-100px", padding: "0"}}>
        <Col style={{backgroundColor:"#000", minHeight: "100%", minWidth: "100%", position: "relative"}}>
          <Row md={12} xs={12} sm={12} style={{color: "#fff"}}>
          <Image style={{zIndex:1, height:"100%", width:"100%",  position: "absolute", bottom: "0", opacity: 0.4 }} responsive src={require("../Images/Santiago.png")}></Image>
              <div style={{minHeight: "100%", marginTop: "100px", paddingLeft: "10%", paddingRight: "10%"}}>
                <h1 style={{fontSize: "xx-large"}} >SOMOS</h1>
                <h4 style={{fontSize: "small"}}>
                Nuestra empresa nace el 2004 ante la necesidad de las distintas industrias de adquirir ropa corporativa personalizada de alta calidad para sus funcionarios, cumpliendo con los diversos requerimientos propios de cada organizaciòn.
                </h4>
                <p style={{fontSize: "x-small"}}>
                Mezclamos maquinaria de última generación con asesoría especializada para poder brindar a nuestros clientes los mejores productos y el más alto nivel de servicio que ofrece el mercado
                </p>
              </div>
          </Row>
          <Row md={12} xs={12} sm={12} style={{justifyContent: "center", alignItems: "center", paddingBottom: "50px"}}>
            <Image style={{zIndex:2, minHeight:100 }} responsive src={require("../Images/cyg.jpg")}></Image>
          </Row>
        </Col>
    </Container>
  );
}

export default SomosMobile;
