import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

const imagePrefix = "data:image/jpeg;base64,";

const ProductModal = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Body>
      <Row>
        <Col md={7}>
          <ImageGallery
          showFullscreenButton={false}
          items={props.product.images ?  props.product.images.map((x) => {
              return {original: x,
                      thumbnail: x}
          }) : images} />
         </Col>
         <Col md={5}>
            <h4>{props.product.nombre ? props.product.nombre : "Cargando nombre"}</h4>
            <p> Descripcion </p>
            <p style={{fontSize: "15px"}}>{props.product.descripcion ? props.product.descripcion : "Cargando descripcion"}</p>
            <p> Especificaciones </p>
            <p style={{fontSize: "15px"}}>{props.product.especificaciones ? props.product.especificaciones : "Cargando especificaciones"}</p>

         </Col>
       </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ProductModal;
