import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Servicio from './Servicio';

import { GiRolledCloth, GiSewingNeedle, GiCoffeeMug } from 'react-icons/gi'


const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}


// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };




const ServiciosDesktop = () => {

  return (
    <Container fluid="md" style={{ maxWidth: "100%", paddingLeft: "0px"}}>
        <Row style={{minHeight: "100%", position: "relative", justifyContent: "center"}}>
          <Col md={3} style={{...paddingStyle}}>
            <Servicio logo={<GiRolledCloth />} servicio={"Ropa corporativa"} descripcion={"Hacemos ropa según las necesidades de tu organización"}/>
          </Col>
          <Col md={3} style={{...paddingStyle}}>
            <Servicio logo={<GiSewingNeedle />} servicio={"Servicio de Bordado"} descripcion={"Bordado de prendas y distintos artículos corporativos y para marketing"}/>
          </Col>
          <Col md={3} style={{...paddingStyle}}>
            <Servicio logo={<GiCoffeeMug />} servicio={"Artículos para Marketing"} descripcion={"Fabricamos artículos para el marketing de tu empresa"}/>
          </Col>
        </Row>
    </Container>
  );
}

export default ServiciosDesktop;
