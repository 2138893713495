import React from 'react';
import ProductosDesktop from './ProductosDesktop';
import ProductosMobile from './ProductosMobile';


import { useViewportDimensions } from '../viewPortContext';

const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};


const Productos = () => {
  const { width, height } = useViewport();
  const breakpoint = 900;

  return (
    width < breakpoint ? <ProductosMobile/> : <ProductosDesktop/>
  );
}

export default Productos;
