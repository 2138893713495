import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import db from '../functions_and_vars';


const SimpleMap = () =>  {
  const [key, setKey] = useState(null);
  const [keyLoaded, setKeyLoaded] = useState(false);

  const api_key = axios.get(db + "/products/mapkey/", {})
                        .then((res) => {
                        setKey(res.data.key);
                        return res;
                        })
                        .then((res) => {
                        setKeyLoaded(true);
                        return res.data.key;
                        });

  const defaultProps = {
    center: {
      lat: -33.423613,
      lng: -70.612246
    },
    zoom: 17
  };

  if (!keyLoaded) {
    return(
      <div style={{ height: '50vh', width: '100%' }}>
          Cargando mapa
      </div>
    )
  }

  if(keyLoaded)
  {
    return (
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key:  key }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
      </GoogleMapReact>
    </div>
  );}
}


export default SimpleMap;
