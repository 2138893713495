import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Overlay from './Overlay';
import { FiPlusCircle } from 'react-icons/fi'

import { useViewportDimensions } from '../viewPortContext';


// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// Para pasar imagen de BD a String leible por Image de React
const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

const imagePrefix = "data:image/jpeg;base64,";

// ------------------------------------------

const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};


const Product = (props) => {
  const { width, height } = useViewport();

  return (

    <Card style = {{ postion: "relative", width: "100%", height: "100%" }}>
      <Overlay image={props.image ? props.image : ""}/>
      <Card.Body>
        <Card.Title style={{fontSize: "20px"}}>{props.titulo}</Card.Title>
      </Card.Body>
    </Card>

  );
}

export default Product;
