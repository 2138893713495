import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}


// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

const ValoresDesktop = () => {

  return (
    <Container className="container-section" fluid="md" style={{backgroundColor: "#f4f6f7", minWidth: "100%"}}>
      <Row>
        <Col md={4} xs={12} className="column-pair-first" style={{...paddingStyle, alignItems: "center"}}>
          <Image style={{ width:"100%"}} responsive src={require("../Images/embroidery-polo-shirts-with-logo.png")}></Image>
        </Col>
        <Col md={8} xs={12} className="column-pair-second" style={paddingStyle}>
              <h1>Nuestros Valores</h1>
              <h4>
              C y G Diseños tiene una reconocida trayectoria y ha tenido un sostenido crecimiento gracias a su seriedad,
               innovación y compromiso irrestricto con sus clientes. Nos comprometemos a entregar el mejor
               servicio y atención a todos quienes confìan en nosotros.
              </h4>
              <h4>
              Nuestros productos se diferencian por su calidad y buen gusto, los cuales se confeccionan de forma personalizada para asegurar la mejor experiencia de compra.
              </h4>
        </Col>
      </Row>
    </Container>
  );
}

export default ValoresDesktop;
