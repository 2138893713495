import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';





import { useViewportDimensions } from '../viewPortContext';

//
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };


const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};


const NavbarCustom = (props) => {
  const [transparentNav, setTransparentNav] = React.useState(true);
  const { width, height } = useViewport();
  // const breakpoint = 620;

  React.useEffect( () => {
    window.onscroll = () => {
      if(window.pageYOffset > 500) {
        setTransparentNav(false);
      } else {
        setTransparentNav(true);
      }
    };
  })

  return (

    <Navbar collapseOnSelect sticky="top" style={{backgroundColor: transparentNav ? "transparent":"#222",
                                 color: "#f4f6f7",
                                 transition: "background-color 0.5s ease 0s"}}>
    <Navbar.Brand href="#home" style={{color: "#f4f6f7"}}>CyG Diseños</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{color: "#f4f6f7"}}/>
    <Navbar.Collapse id="responsive-navbar-nav" style={{color: "#f4f6f7"}}>
          {width > 900 ? props.desktop : props.mobile}
    </Navbar.Collapse>
  </Navbar>

  );
}

export default NavbarCustom;
