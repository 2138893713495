import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';

import { useViewportDimensions } from '../viewPortContext';


const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};

const styleServicio = {
  height: "50vh",
  boxShadow: "5px 5px 15px 5px #9E9E9E",
  transition: "background-color 0.5s ease 0s",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexFlow: "column"
}

const styleServicioNormal = {
  backgroundColor: "white",
  color: "#222"
}

const styleServicioHovered = {
  backgroundColor: "#222",
  color: "white"
}

const Servicio = (props) => {
  const [hovered, updateHovered] = useState(false);

  const handleMouseEnter = () => {
      updateHovered(true);
    };

  const handleMouseLeave = () => {
      updateHovered(false);
    };

  const { width, height } = useViewport();

  return (

    <div style={hovered ? {...styleServicio, ...styleServicioHovered} : {...styleServicio, ...styleServicioNormal} }
          onMouseEnter={(e) => handleMouseEnter()}
          onMouseLeave={(e) => handleMouseLeave()}>
          <div style={{width: "70%", fontSize: "80px"}}>
            <p>{props.logo}</p>
          </div>
          <div style={{width: "70%", fontSize: "25px", marginTop: "-50px"}}>
            <p>{props.servicio}</p>
          </div>
          <div style={{width: "70%"}}>
            <p>{props.descripcion}</p>
          </div>
    </div>

  );
}

export default Servicio;
