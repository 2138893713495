import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {scroller, Element} from 'react-scroll';
import { GiHamburgerMenu } from 'react-icons/gi'


// Componentes propios
import Clientcarousel from './Components/Carousel';
import ContactForm from './Components/ContactForm';
import Somos from './Components/Somos';
import Valores from './Components/Valores';
import NavbarCustom from './Components/NavbarCustom';
import Productos from './Components/Productos';
import ClientesDesktop from './Components/ClientesDesktop';
import ServiciosDesktop from './Components/ServiciosDesktop';
import SimpleMap from './Components/Map'


// Context
import ViewportProvider, { useViewportDimensions } from './viewPortContext';
import {ProductsProvider} from './productsContext';


// Importar imagenes
import Cliente1 from './Images/cocacola.png'
import Cliente2 from './Images/falabella.jpg'
import Cliente3 from './Images/pronto.png'
import Cliente4 from './Images/upa.jpeg'

import db from './functions_and_vars';


const clientes = [Cliente1, Cliente2, Cliente3, Cliente4]
const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}



const scrollProductos = (id) => {
  scroller.scrollTo(id, {
    duration: 1500,
    delay: 0,
    smooth: true
  });
}


const App = () => {

    return (
      <ViewportProvider>
        <Router>
          <div className="App">
            <NavbarCustom
              desktop={
                <Nav className="ml-auto" style={{color: "#f4f6f7"}}>
                  <Nav.Link href="#somos" style={{color: "#f4f6f7"}} onClick={() => scrollProductos("Somos")}>Somos</Nav.Link>
                  <Nav.Link href= "#nuestrosvalores" style={{color: "#f4f6f7"}} onClick={() => scrollProductos("Valores")}>Nuestros Valores</Nav.Link>
                  <Nav.Link href="#productos" style={{color: "#f4f6f7"}} onClick={() => scrollProductos("Productos")}>Productos</Nav.Link>
                  <Nav.Link href="#nuestros-clientes" style={{color: "#f4f6f7"}} onClick={() => scrollProductos("Clientes")}>Nuestros Clientes</Nav.Link>
                  <Nav.Link href="#contacto" style={{color: "#f4f6f7"}} onClick={() => scrollProductos("Contacto")}>Contacto</Nav.Link>
                </Nav>}
              mobile={
                <Nav className="ml-auto" style={{color: "#f4f6f7"}}>
                  <NavDropdown style={{borderStyle: "solid", borderWidth:"1px"}} title={<GiHamburgerMenu style={{color: "#f4f6f7"}}/>} id="basic-nav-dropdown"  drop="down" alignRight >
                    <NavDropdown.Item href="#somos" onClick={() => scrollProductos("Somos")}>Somos</NavDropdown.Item>
                    <NavDropdown.Item href="#nuestrosvalores" onClick={() => scrollProductos("Valores")}>Nuestros Valores</NavDropdown.Item>
                    <NavDropdown.Item href="#productos" onClick={() => scrollProductos("Productos")}>Productos</NavDropdown.Item>
                    <NavDropdown.Item href="#nuestros-clientes" onClick={() => scrollProductos("Clientes")}>Nuestros Clientes</NavDropdown.Item>
                    <NavDropdown.Item href="#contacto" onClick={() => scrollProductos("Contacto")}>Contacto</NavDropdown.Item>
                  </NavDropdown>
                </Nav>}
              >

            </NavbarCustom>

            {/*Somos*/}
            <Element name="Somos">
              <Somos/>
            </Element>

            <ServiciosDesktop/>

            {/*Nuestros Valores*/}
            <Element name="Valores">
              <Valores/>
            </Element>

            {/* Productos */}
            <Element name="Productos">
              <ProductsProvider>
              {/*  <ProductosDesktop/> */}
              <Productos/>
              </ProductsProvider>
            </Element>


{/*            <Element name="Clientes">
              <Container className="container-products" fluid="md" style={{paddingTop: "5%", backgroundColor: "#f4f6f7", minWidth: "100%"}}>
                <h1 style={{marginBottom: 100}}>NUESTROS CLIENTES</h1>
                <ClientesDesktop Clientes={clientes}>
                </ClientesDesktop>
              </Container>
            </Element> */}

            {/*Clientes
            <Element name="Clientes">
              <Container className="container-products" fluid="md" style={{paddingTop: "5%", backgroundColor: "#f4f6f7", minWidth: "100%"}}>
                <h1 style={{marginBottom: 100}}>NUESTROS CLIENTES</h1>
                <Clientcarousel Clientes={clientes}>
                </Clientcarousel>
              </Container>
            </Element>
          */}

            {/* Contacto */}
            <Element name="Contacto">
              <Container className="container-products" fluid="md" style={{paddingTop: "5%", textAlign: "center", minWidth: "100%"}}>
              <h1 style={{marginBottom: 100}}>CONTACTO</h1>
                <Row style={{marginBottom: 50}}>
                  <Col md={1}></Col>
                  <Col md={5} style={{justifyContent: "center"}}>
                    <SimpleMap style={{position: "absolute"}}/>
                    <p/>
                    <p>Dardignac 265, Oficina C  - Recoleta</p>
                    <p>+56227380632 - +56996991630 - gramirez@cygdisenos.cl</p>
                  </Col>
                  <Col md={5} style={{textAlign: "left"}}>
                    <ContactForm post={db + "/products/consulta/"}/>
                  </Col>
                  <Col md={1}>
                  </Col>
                </Row>
              </Container>
            </Element>


            {/* Footer */}
            <Container md={12} style={{display:"flex", maxWidth: "100%", height:"80px", backgroundColor:"#222", alignItems: "center", justifyContent: "center"}}>
              <p style={{color: "#f4f6f7"}}>+56227380632 - +56996991630 - gramirez@cygdisenos.cl | CyG DISENOS</p>
            </Container>
          </div>
        </Router>
      </ViewportProvider>

    );

}

export default App;
