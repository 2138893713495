// Para crear un solo event listener para todos los componentes al hacer resize
// sacado de https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
// https://es.reactjs.org/docs/context.html
// https://hackernoon.com/simplifying-responsive-layouts-with-react-hooks-19db73893a7a


// https://stackoverflow.com/questions/50502664/how-to-update-the-context-value-in-provider-from-the-consumer


import React, { createContext } from 'react';
import axios from 'axios';
import db from './functions_and_vars';


const ProductsContext = createContext({});

const dummy = {
  data: [
    {
        nombre: "",
        images: [""]
    }
  ]
};


const ProductsProvider = ({ children }) => {

  const [products, setProducts] = React.useState(dummy);

  React.useEffect(() => {
    axios.get(db + "/products/category/:", {
      params: {
        clasificacion: 1,
        subclass: 1
      }
    }).then((res) => {
      setProducts(res);
    }).catch((err) => {
      console.log(err);
    });
  }, []);



  // Generar funcion de Update de estaado de productos. Pasarla como prop dentro del provider. Consumir desde children
  const updateProducts = (products) => {
    setProducts(products);
  };

  return (
    <ProductsContext.Provider value={{ products, updateProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};

export {ProductsProvider, ProductsContext};
