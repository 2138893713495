import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaTshirt } from 'react-icons/fa'
import { GiMonclerJacket, GiTrousers, GiPoloShirt, GiKevlarVest } from 'react-icons/gi'
import { RiTShirtLine, RiShirtLine, RiHandbagLine } from 'react-icons/ri'
import axios from 'axios';
import db from '../functions_and_vars';

import ViewportProvider, { useViewportDimensions } from '../viewPortContext';
import { ProductsProvider, ProductsContext }  from '../productsContext'


const paddingStyle = {paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const navLinkHeight = "50px";

const menuStyle = {
                   display: "flex",
                   alignItems: "center",
                   alignContent: "center",
                   justifyContent: "flex-start",
                   height: "100%",
                   paddingLeft: "25px",
                   paddingRight: "20px",
                   borderBottom: "1px solid grey"
                 };

const subMenuWrapStyle = {
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                          justifyContent: "center",
                          flexDirection: "column"
                        };

const subMenuStyle = {
                      display:"flex",
                      height: navLinkHeight,
                      width: "100%",
                      alignItems:"center",
                      alignContent: "center",
                      fontSize: "15px",
                      paddingLeft:"15%"
                     };

const useViewport = () => {
  const { width, height } = useViewportDimensions(); // Del React.context
  return { width, height };
};

const useProductsContext = () => {
  const { products, updateProducts } = useContext(ProductsContext);
  return { products, updateProducts };
};


const ProductosSidebar = (props) => {

  const { width, height } = useViewport();
  const breakpoint = 620;
  const { products, updateProducts } = useProductsContext();

  const getProducts = (clasificacion, subclass) => {
    axios.get(db + '/products/category/' + clasificacion, {
      params: {
        clasificacion: clasificacion,
        subclass: subclass
      }
    }).then((res) => {
      updateProducts(res);
    }).catch((err) => {
      console.log(err);
    });
  };


  return (
        <Accordion style={{ background: "#222", minHeight: "100%",  width: "100%" }}>
          <Accordion.Toggle as={Dropdown}
                            eventKey="0"
                            style={{ color: "white", height: navLinkHeight }}
                            >
            <div style={ menuStyle }>
              <FaTshirt style = {{ marginRight: "15px" }}/>
              <div style = {{ float: "left" }}>Poleras y polerones</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="0">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(1, 1)} style={ subMenuStyle }>Polerones</div>
                <div onClick = {(e) => getProducts(1, 2)} style={ subMenuStyle }>Polo T Shirts (cuello camisero)</div>
                <div onClick = {(e) => getProducts(1, 3)} style={ subMenuStyle }>Profesional</div>
                <div onClick = {(e) => getProducts(1, 4)} style={ subMenuStyle }>T Shirts (cuello redondo)</div>
                <div onClick = {(e) => getProducts(1, 5)} style={ subMenuStyle }>Vial</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown}  eventKey="1" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <GiMonclerJacket style = {{ marginRight: "15px" }}/>
              <div>Parkas y chaquetas</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="1">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(2, 1)} style={ subMenuStyle }>3 en 1</div>
                <div onClick = {(e) => getProducts(2, 2)} style={ subMenuStyle }>Cortaviento</div>
                <div onClick = {(e) => getProducts(2, 3)} style={ subMenuStyle }>Impermeable</div>
                <div onClick = {(e) => getProducts(2, 4)} style={ subMenuStyle }>Micropolar</div>
                <div onClick = {(e) => getProducts(2, 5)} style={ subMenuStyle }>Profesional</div>
                <div onClick = {(e) => getProducts(2, 6)} style={ subMenuStyle }>Softshell</div>
                <div onClick = {(e) => getProducts(2, 7)} style={ subMenuStyle }>Térmica</div>
                <div onClick = {(e) => getProducts(2, 8)} style={ subMenuStyle }>Vial</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown}  eventKey="3" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <RiTShirtLine style = {{ marginRight: "15px" }}/>
              <div>Camisas y blusas</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="3">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(3, 1)} style={ subMenuStyle }>Ejecutiva</div>
                <div onClick = {(e) => getProducts(3, 2)} style={ subMenuStyle }>Profesional</div>
                <div onClick = {(e) => getProducts(3, 3)} style={ subMenuStyle }> Urban</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown}  eventKey="2" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <GiTrousers style = {{ marginRight: "15px" }}/>
              <div>Pantalones y Jeans</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="2">
              <div style={ subMenuWrapStyle }>
                    <div onClick = {(e) => getProducts(4, 1)} style={ subMenuStyle }>Denim</div>
                    <div onClick = {(e) => getProducts(4, 2)} style={ subMenuStyle }>Ejecutiva</div>
                    <div onClick = {(e) => getProducts(4, 3)} style={ subMenuStyle }>Profesional</div>
                    <div onClick = {(e) => getProducts(4, 4)} style={ subMenuStyle }>Térmica</div>
                    <div onClick = {(e) => getProducts(4, 5)} style={ subMenuStyle }>Vial</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown} eventKey="4" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <RiShirtLine style = {{ marginRight: "15px" }}/>
              <div>Ropa de trabajo</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="4">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(5, 1)} style={ subMenuStyle }>Camisas y Blusas</div>
                <div onClick = {(e) => getProducts(5, 2)} style={ subMenuStyle }>Geologos</div>
                <div onClick = {(e) => getProducts(5, 3)} style={ subMenuStyle }>Overoles</div>
                <div onClick = {(e) => getProducts(5, 4)} style={ subMenuStyle }>Primera Capa</div>
                <div onClick = {(e) => getProducts(5, 5)} style={ subMenuStyle }>Térmica</div>
                <div onClick = {(e) => getProducts(5, 6)} style={ subMenuStyle }>Vial</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown}  eventKey="5" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <GiKevlarVest style = {{ marginRight: "15px" }}/>
              <div>Ropa técnica</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="5">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(6, 1)} style={ subMenuStyle }>Antiacido</div>
                <div onClick = {(e) => getProducts(6, 2)} style={ subMenuStyle }>Ignifugo</div>
                <div onClick = {(e) => getProducts(6, 3)} style={ subMenuStyle }>PVC</div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle as={Dropdown}  eventKey="6" style={{ color: "white", height: navLinkHeight }}>
            <div style={ menuStyle }>
              <RiHandbagLine style = {{ marginRight: "15px" }}/>
              <div>Accesorios</div>
            </div>
            </Accordion.Toggle>
            <Accordion.Collapse style={{ background: "#333333", color: "white" }} eventKey="6">
              <div style={ subMenuWrapStyle }>
                <div onClick = {(e) => getProducts(7, 1)} style={ subMenuStyle }>Balaclava</div>
                <div onClick = {(e) => getProducts(7, 2)} style={ subMenuStyle }>Gorro</div>
                <div onClick = {(e) => getProducts(7, 3)} style={ subMenuStyle }>Jockey</div>
              </div>
            </Accordion.Collapse>
          </Accordion>

  );
}

export default ProductosSidebar;
